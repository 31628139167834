import React, { Component } from "react";
import { Alert, Button, Col, Grid, PageHeader, Row, Table } from 'react-bootstrap';
import { API } from 'aws-amplify';

export default class GameStatus extends Component {
    state = {
        loading: true
    };

    async componentDidMount() {
        const game = await API.get("spf", "/games");
        this.setState({
            game: game,
            loading: false
        });
        return;
    }

    render() {
        const innerComponent = this.state.loading ? this.renderSpinner() : this.renderActualGameStatus();

        return (
            <div className="GameStatus">
                {innerComponent}
            </div>
        );
    }

    renderSpinner() {
        return (<h1>This'll be a spinner eventually</h1>)
    }

    renderActualGameStatus() {
        return (
            <div className="ActualGameStatus">
                {this.renderStatusOverview()}
                {this.renderChangeStatusSection()}
                {this.renderAttendeesTable()}
                {this.renderDetails()}
            </div>
        )
    }

    renderAttendeesTable() {
        return (
            <div className="GameAttendees">
                <PageHeader>Attendees</PageHeader>
                <Table>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Has The Bag</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.game.attendees.map(this.attendeeToRow)}
                    </tbody>
                </Table>
            </div>
        )
    }

    attendeeToRow(attendee) {
        return (
            <tr key={attendee.name}>
                <td>{attendee.name}</td>
                <td>{attendee.hasBag ? "Yes" : ""}</td>
            </tr>
        )
    }

    renderChangeStatusSection() {
        let participationButton, bagButton

        if (!this.activeUserIsAttending()) {
            participationButton = <Button
                bsSize="large"
                bsStyle="success"
                onClick={this.toggleParticipation}
                block
            >
                I'm In!
            </Button>
        } else {
            participationButton = <Button
                bsSize="large"
                bsStyle="danger"
                onClick={this.toggleParticipation}
                block
            >
                I'm Out
            </Button>
        }

        if (!this.activeUserHasBag()) {
            bagButton = <Button
                bsSize="large"
                bsStyle="success"
                onClick={this.toggleBagHavingness}
                block
            >
                I have the bag (or a bag)!
            </Button>
        } else {
            bagButton = <Button
                bsSize="large"
                bsStyle="danger"
                onClick={this.toggleBagHavingness}
                block
            >
                I no longer have the bag
            </Button>
        }

        return (
            <div className="ChangeStatus">
                <PageHeader>Change Status</PageHeader>
                <Grid>
                    <Row>
                        <Col xs={6} sm={6} md={6} lg={6}>
                            {participationButton}
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6}>
                            {bagButton}
                        </Col>
                    </Row>
                </Grid>
            </div>
        )
    }

    activeUserIsAttending() {
        return true; // Placeholder
    }

    activeUserHasBag() {
        return true; // Placeholder
    }

    toggleParticipation() {
        return;
    }

    toggleBagHavingness() {
        return;
    }

    renderStatusOverview() {
        const title = (this.state.game.hasBag && this.state.game.hasNumbers) ? "The Game Is On!" : "The Game Is NOT On";

        let numbersAlert, bagAlert;
        let numAttendees = this.state.game.attendees.length;

        if (this.state.game.hasNumbers) {
            numbersAlert = <Alert bsStyle="success">We have numbers! We need 6 and we have {numAttendees}</Alert>
        } else {
            numbersAlert = <Alert bsStyle="danger">We still need {6 - numAttendees} more players to hit the 6 player minimum</Alert>
        }

        if (this.state.game.hasBag) {
            bagAlert = <Alert bsStyle="success">Somebody attending the game has flags, a ball, and cones</Alert>
        } else {
            bagAlert = <Alert bsStyle="danger">Nobody has the bag with flags, cones, and a ball</Alert>
        }

        return (
            <div className="GameStatusOverview">
                <PageHeader>{this.state.game.date} - {title}</PageHeader>
                {numbersAlert}
                {bagAlert}
            </div>
        )
    }

    renderDetails() {
        return (
            <div className="GameDetails">
                <PageHeader>Game Details</PageHeader>
                <p>"9:45AM" on {this.state.game.date} at Cal Anderson, if it's busy we'll go to Garfield, and if
                that fails then we'll figure it out.</p>
            </div>
        )
    }
}