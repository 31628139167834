import React, { Component } from "react";

export default class About extends Component {
    render() {
        return (
            <div className="About">
                <div className="lander">
                    <h1>About Page</h1>
                    <p>This website originated from the seattle-pickup-football@amazon.com mailing list, which
                    has hosted a recurring flag football game since 2012. We hold a pickup flag football game
                    every Saturday morning at Cal Anderson or Garfield HS.</p>
                </div>
            </div>
        );
    }
}
