// Taken from https://serverless-stack.com/chapters/add-the-session-to-the-state.html, helps pass state down
// to children. We should probably just use Redux or something instead, but I want to go functional then clean shit
// up later.

import React from "react";
import { Route } from "react-router-dom";

// In the example this was a gross one-liner that also called parentProps "cProps" (for child props) because
// who knows why...
export default ({ component: Child, parentProps: parentProps, ...otherArgs }) => {
    const render = (childProps) => <Child {...childProps} {...parentProps} />;
    return <Route {...otherArgs} render={render}/>;
}
