import React, { Component, Fragment } from 'react';
import './App.css';
import { Link, withRouter } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Nav, NavItem, Navbar } from "react-bootstrap";
import Routes from './Routes';
import { Auth } from 'aws-amplify';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true
    };
  }

  async componentDidMount() {
    try {
      await Auth.currentSession();
      this.setAuthenticatedStatus(true);
    }
    catch(e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }

    this.setState({ isAuthenticating: false });
  }

  setAuthenticatedStatus = status =>  {
    this.setState({isAuthenticated: status});
  };

  handleLogout = async event => {
      await Auth.signOut();
      this.setAuthenticatedStatus(false);
      this.props.history.push("/login");
  };

  render() {
    const parentProps = {
      isAuthenticated: this.state.isAuthenticated,
      isAuthenticating: this.state.isAuthenticating,
      setAuthenticatedStatus: this.setAuthenticatedStatus
    };

    let signInAndOutFragment;

    if (this.state.isAuthenticated) {
      signInAndOutFragment = <Fragment>
        <NavItem onClick={this.handleLogout}>Log Out</NavItem>
      </Fragment>
    } else {
      signInAndOutFragment = <Fragment>
        <LinkContainer to="/signup">
          <NavItem>Signup</NavItem>
        </LinkContainer>
        <LinkContainer to="/login">
          <NavItem>Login</NavItem>
        </LinkContainer>
      </Fragment>
    }

    return (
        <div className="AppContainer">
          <Navbar fluid collapseOnSelect>
            <Navbar.Header>
              <Navbar.Brand>
                <Link to="/">Seattle Pickup Football</Link>
              </Navbar.Brand>
            </Navbar.Header>
            <Navbar.Collapse>
              <Nav pullRight>
                <LinkContainer to="/about">
                  <NavItem>About</NavItem>
                </LinkContainer>
                {signInAndOutFragment}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <div className="container-fluid">
            <Routes parentProps={parentProps}/>
          </div>
        </div>
    );
  }
}

// Needed so we have access to the routers's properties
export default withRouter(App);
