export default {
    apiGateway: {
        REGION: "us-west-2",
        URL: "https://amo88pnuyl.execute-api.us-west-2.amazonaws.com/dev"
    },
    cognito: {
        REGION: "us-west-2",
        USER_POOL_ID: "us-west-2_aeSjwUDEQ",
        APP_CLIENT_ID: "24asdp9vauqvp4a6k7n006nmbm",
        IDENTITY_POOL_ID: "us-west-2:a6ee5d3d-3fe8-45ae-aa84-16bebc042a5a"
    }
}