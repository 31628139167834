import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LoginForm from './containers/LoginForm';
import Signup from './containers/Signup';
import GameStatus from './containers/GameStatus';
import About from './containers/About';
import NotFound from './containers/NotFound';

import AppliedRoute from "./components/AppliedRoute";
import RedirectOnAuthRoute from "./components/RedirectOnAuthRoute";

export default ({parentProps}) =>
    <Switch>
        <RedirectOnAuthRoute path='/'  redirectCriteria={true} redirectTarget="/login" exact component={GameStatus} parentProps={parentProps}/>
        <RedirectOnAuthRoute path='/index.html'  redirectCriteria={true} redirectTarget="/login" exact component={GameStatus} parentProps={parentProps}/>
        <AppliedRoute path='/about' exact component={About} parentProps={parentProps}/>
        <RedirectOnAuthRoute path='/status' redirectCriteria={true} redirectTarget="/login" exact component={GameStatus} parentProps={parentProps}/>
        <RedirectOnAuthRoute path='/login' redirectCriteria={false} redirectTarget="/" exact component={LoginForm} parentProps={parentProps}/>
        <RedirectOnAuthRoute path='/signup' redirectCriteria={false} redirectTarget="/" exact component={Signup} parentProps={parentProps}/>
        { /* Default to 404 Page */ }
        <Route component={NotFound} />
    </Switch>;